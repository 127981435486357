/* eslint-env browser */
import { RefObject, useEffect } from 'react';

const useScrollPosition = (containerRef: RefObject<HTMLElement | null>, targetRef: RefObject<HTMLElement | null>) => {
  useEffect(() => {
    if (targetRef.current) {
      const handleScroll = (event: any) => {
        if (containerRef.current) {
          const scrollEnd = event.target.scrollWidth - event.target.getBoundingClientRect().width;

          if (event.target.scrollLeft >= scrollEnd) containerRef.current.classList.add('scroll-end');
          else containerRef.current.classList.remove('scroll-end');

          if (event.target.scrollLeft <= 0) containerRef.current.classList.add('scroll-start');
          else containerRef.current.classList.remove('scroll-start');
        }
      };

      targetRef.current.addEventListener('scroll', handleScroll);

      return () => targetRef.current?.removeEventListener('scroll', handleScroll);
    }

    return () => {};
  }, []);
};

export default useScrollPosition;
