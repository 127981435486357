import { mobileXLargeMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import { SearchInputIcon, Container as SearchInput } from '@propertypal/web-ui/src/inputs/SearchInput.style';
import { Input as TextInputInput } from '@propertypal/web-ui/src/inputs/TextInput.style';
import styled from 'styled-components';

export const BREAKPOINT = '780px';

export const Container = styled.div`
  position: relative;
  z-index: 5;
  max-width: 760px;
  width: 100%;
  margin: 70px 0 40px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 6px;

  @media (max-width: ${BREAKPOINT}) {
    margin-top: 60px;
    width: calc(100% - 30px);
  }
`;

export const Tab = styled.button<{ active: boolean }>`
  color: ${({ active, theme }) => theme[active ? 'textDark' : 'backgroundMid']};
  font-weight: bold;
  position: relative;
  transition: color 200ms linear;
  font-size: 16px;
  margin: 0 8px;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.textDark};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background-color: ${({ theme, active }) => (active ? theme.primary : 'transparent')};
    transition: background-color 200ms linear;
  }
`;

export const TabRail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;

  @media (${mobileXLargeMax}) {
    padding: 0 0 15px;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
    mask: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const TabArrow = styled.div<{ direction: 'left' | 'right' }>`
  display: none;
  position: absolute;
  width: 20px;
  height: 26px;
  z-index: 2;
  align-items: center;

  ${(props) =>
    props.direction === 'left' &&
    `
    left: 0;
  `}

  ${(props) =>
    props.direction === 'right' &&
    `
    right: 0;
    justify-content: flex-end;
  `}

  @media (${mobileXLargeMax}) {
    display: flex;
  }
`;

export const TabContainer = styled.div`
  position: relative;

  &.scroll-start ${TabRail} {
    mask: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
  }

  &.scroll-start .arrow-left {
    display: none;
  }

  &.scroll-end ${TabRail} {
    mask: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 100%);
  }

  &.scroll-end .arrow-right {
    display: none;
  }
`;

export const SearchForm = styled.form<{ instantVal?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column;
  }

  ${TextInputInput} {
    height: 54px;

    &::placeholder {
      color: ${({ theme }) => theme.backgroundLight};
    }

    &::selection {
      color: ${({ theme }) => theme.white};
      background: ${({ theme }) => theme.primary};
    }
  }
`;

export const SearchInputContainer = styled.div`
  flex: 1;

  @media (${mobileXXLargeMax}) {
    ${SearchInput} {
      padding-left: 15px;
    }

    ${SearchInputIcon} {
      display: block;
    }
  }
`;

export const SearchButtons = styled.div<{ multiButtons?: boolean }>`
  display: flex;
  flex-basis: ${(props) => (props.multiButtons ? '380px;' : '240px')};

  & > button {
    flex: 1;
    margin-left: 6px;
  }

  @media (max-width: ${BREAKPOINT}) {
    position: absolute;
    bottom: -60px;
    width: 100%;
    left: 0;

    & > button:first-child {
      margin-left: 0;
    }
  }
`;
